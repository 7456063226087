import React from 'react';
import { ColumnLayout, Container, Header, Link, Spinner, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { renderElement, renderField, renderLink } from './tableUtils';
import { buildHostForDomainAndRegion, getDomainFromHost } from '../../utils';
import BrokerTagsComponent from './BrokerTagsComponent';
import { Broker, BrokerEngineType, BrokerMaintenanceInfo, BrokerSummary, DRMode, EC2Instance } from '@amzn/amazonmq-opsconsole-client';
import { DateTime } from 'luxon';

type Props = {
    broker: Broker,
    brokerMaintenance: BrokerMaintenanceInfo,
    brokerInstances: EC2Instance[] | undefined
}

function renderBrokerName(brokerSummary: BrokerSummary): React.ReactElement {
    if (brokerSummary.brokerState === 'AVAILABLE') {
        return <span>{brokerSummary.name} <StatusIndicator type='success'>{brokerSummary.brokerState}</StatusIndicator></span>
    } else if (brokerSummary.brokerState.indexOf('FAILED') >= 0) {
        return <span>{brokerSummary.name} <StatusIndicator type='error'>{brokerSummary.brokerState}</StatusIndicator></span>
    } else {
        return <span>{brokerSummary.name} <StatusIndicator type='info'>{brokerSummary.brokerState}</StatusIndicator></span>
    }
}

function renderCRDRSection(brokerSummary: BrokerSummary) : React.ReactNode {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>Running: {brokerSummary.drMode}</span>
            <span>Pending: {brokerSummary.pendingDrMode}</span>
            {brokerSummary.replicationRole &&
                <span>Replication Role: {brokerSummary.replicationRole}</span>
            }
            {brokerSummary.drCounterPart &&
                <Link external={true} target='_blank' href={`https://${buildHostForDomainAndRegion(getDomainFromHost(), brokerSummary.drCounterPart.region)}/broker/${brokerSummary.drCounterPart.brokerId}`}>Counterpart</Link>
            }
        </div>
    )
}

const BrokerSummaryTable : React.FC<Props> = ({broker, brokerMaintenance, brokerInstances}) => {

    const brokerSummary = broker.summary;
    const localDate = new Date();
    let nextCfnMwDate: Date | null = null;
    const parsedMW = parseMaintenanceWindowStartTime(brokerMaintenance.maintenanceWindow);
    const formattedMW = parsedMW ? formatMaintenanceWindowDate(parsedMW) : brokerMaintenance.maintenanceWindow;
    if(parsedMW){
        nextCfnMwDate = getNextMWDate(broker.id, localDate, parsedMW );
    }
    const nextCfnMwDateDisplay = nextCfnMwDate ? formatDateToString(nextCfnMwDate) : 'Condition not met';
    let content : React.ReactElement[] = [];
    content.push(renderElement("Name", renderBrokerName(brokerSummary), brokerSummary.name));
    content.push(renderField("Host Instance Type", brokerSummary.hostInstanceType, "Pending: " + broker.brokerInfo.pendingHostInstanceType));
    content.push(renderField("Quarantine States", brokerSummary.quarantineStates));
    content.push(renderLink("Customer Account ID", brokerSummary.accountId, brokerSummary.accountLink, "Account Link"));
    content.push(renderField("Engine", broker.brokerInfo.engine, "Pending: " + broker.brokerInfo.pendingEngine));
    content.push(renderField("Deployment Mode", brokerSummary.deploymentMode));
    content.push(renderField("Maintenance Window Start Time", formattedMW));
    content.push(renderLink("Dataplane Cell ID", broker.brokerInfo.cellId, broker.brokerInfo.cellIsenLink, "Cell Link"));
    if (brokerSummary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(<BrokerTagsComponent brokerId={broker.id} />);
    }
    content.push(renderElement("Node Mapping", renderNodeMapping(brokerInstances), brokerNodeMappingCopyText(brokerInstances)));
    // Next CFN MW should be insync with `SchedulePatchingConditionRetriever`. Modify this accordingly.
    // TODO: Get Next CFN MW from `SchedulePatchingConditionRetriever`
    if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(renderField("Next CFN MW", nextCfnMwDateDisplay));
    }
    if (brokerSummary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
        content.push(renderField("Broker Instance Internal Tags",
            brokerSummary.brokerInstanceInternalTags.length > 0 ? brokerSummary.brokerInstanceInternalTags.join(", ") : "None"));
    }
    content.push(renderLink("Region", broker.regionName, `https://${buildHostForDomainAndRegion(getDomainFromHost(), broker.regionName)}`));
    if (brokerSummary.drMode !== DRMode.NONE) {
        content.push(renderElement("DR Mode", renderCRDRSection(brokerSummary), ""))
    }

    return (
        <Container header={
            <Header variant="h2">
                Broker Summary
            </Header>
        }>
            <ColumnLayout columns={4} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}

export const formatDateToString = (date: Date | null): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        hour12: true
    };

    const formattedDate = date ? date.toLocaleDateString('en-US', options) : null;
    return `${formattedDate} UTC`;
};

const formatMaintenanceWindowDate = (maintenanceWindow: { dayOfWeek: string, timeOfDay: string, timeZone: string }) => {
    const [hour, minute] = maintenanceWindow.timeOfDay.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;

    return `${maintenanceWindow.dayOfWeek}, ${hour12}:${minute.toString().padStart(2, '0')} ${period} ${maintenanceWindow.timeZone}`;
};

const getWeekOfEpoch = (date: Date): number => {
    const epoch = DateTime.fromISO('1970-01-01T00:00:00Z', { zone: 'utc' });
    const currentDate = DateTime.fromJSDate(date, { zone: 'utc' });

    const daysSinceEpoch = currentDate.diff(epoch, 'days').days;
    return Math.floor(daysSinceEpoch / 7);
};

const getNextMWDate = (brokerId: string, startDate: Date, maintenanceWindow: { dayOfWeek: string, timeOfDay: string, timeZone: string }): Date | null => {
    const dayOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const targetDay = dayOfWeek.indexOf(maintenanceWindow.dayOfWeek.toLowerCase());

    const startUtcDate = convertToUtc(maintenanceWindow.timeOfDay, maintenanceWindow.timeZone, startDate);
    let date = new Date(startUtcDate);

    // Calculate the number of days until the next target day
    let daysToNextTargetDay = (targetDay - date.getUTCDay() + 7) % 7;
    if (daysToNextTargetDay === 0) {
        daysToNextTargetDay = 7;
    }

    date.setUTCDate(date.getUTCDate() + daysToNextTargetDay);
    
    let weekOfEpoch = getWeekOfEpoch(date);
    let charValue = parseInt(brokerId.charAt(2), 16);

    // Adjust for the 3-week rule
    const targetWeekMod = weekOfEpoch % 3;
    const charValueMod = charValue % 3;
    if (targetWeekMod !== charValueMod) {
        const weeksToAdd = (charValueMod - targetWeekMod + 3) % 3;
        date.setUTCDate(date.getUTCDate() + weeksToAdd * 7);

        if (date.getUTCDay() !== targetDay) {
            date.setUTCDate(date.getUTCDate() - ((date.getUTCDay() - targetDay + 7) % 7));
        }
    }

    return date;

};

const parseMaintenanceWindowStartTime = (timeString: string) => {
    const cleanedString = timeString.replace(/MaintenanceWindowStartTime\{|\}/g, '').trim();
    const params = cleanedString.split(',').map(param => param.trim().split('='));
    const dayOfWeek = params.find(param => param[0] === 'dayOfWeek')?.[1];
    const timeOfDay = params.find(param => param[0] === 'timeOfDay')?.[1];
    const timeZone = params.find(param => param[0] === 'timeZone')?.[1];

    if (dayOfWeek && timeOfDay && timeZone) {
        return {
            dayOfWeek,
            timeOfDay,
            timeZone,
        };
    }
    return null;
};

const convertToUtc = (timeOfDay: string, timeZone: string, date: Date): Date => {
    const [hour, minute] = timeOfDay.split(':').map(Number);
    const time = DateTime.fromObject({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour, minute },
        { zone: timeZone }
    );

    return new Date(time.toUTC().toISO() as string);
};

function renderNodeMapping(brokerInstances: EC2Instance[] | undefined): React.ReactNode {
    if (brokerInstances === undefined) {
        return <Spinner />
    } else {
        let ret: React.ReactElement[] = [];
        for (let i = 0; i < brokerInstances.length; i++) {
            ret.push(<span key={i}>{brokerInstances[i].instanceId + " --> " + brokerInstances[i].privateIp}<br /></span>);
        }
        return ret;
    }
}

function brokerNodeMappingCopyText(brokerInstances: EC2Instance[] | undefined): string {
    if (brokerInstances) {
        return brokerInstances
            .map(brokerInstance => `${brokerInstance.instanceId} --> ${brokerInstance.privateIp}`)
            .join("\n");
    }
    return "Node Mapping not available";
}


export default BrokerSummaryTable;