import React, { useEffect, useState } from 'react';
import { Container, Flashbar, Header, Spinner } from  '@amzn/awsui-components-react/polaris';
import JSONPretty from 'react-json-pretty';
import { getRabbitClusterStatus } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { Broker } from '@amzn/amazonmq-opsconsole-client';
import { ClusterStatus } from '../../../types';


type Props = {
    broker: Broker,
}

const ClusterStatusPage : React.FC<Props> = ({broker}) => {
    const [clusterStatus, setClusterStatus] = useState<ClusterStatus | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitClusterStatus(broker.id).then(response => {
            setClusterStatus(response.clusterStatus as ClusterStatus);
        }).catch(error => {
            console.error(error);
            setError("Could not load cluster status");
        }).finally(() => {
            setLoading(false);
        });
    
    }, [broker.id]);

    return (
        <div>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
                <Container header={
                    <Header variant='h2'>
                        Cluster Status
                    </Header>
                }>
                     {loading && <Spinner /> }
                     {!loading && !error && clusterStatus &&
                        <>
                            <CopyToClipboardButton text={JSON.stringify(clusterStatus)} />
                            <JSONPretty data={clusterStatus}></JSONPretty>
                        </>
                     }
                </Container>
        </div>
    );
}

export default ClusterStatusPage;