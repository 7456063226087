import React, { useState } from 'react';
import { Button, Checkbox, Header, Link, SpaceBetween, StatusIndicator, Table, TableProps, TextFilter } from '@amzn/awsui-components-react/polaris';
import { isoFormat } from 'd3-time-format';
import useConfig from '../../hooks/useConfig';
import { createIsengardLink } from '../../utils';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useNavigate } from 'react-router';
import { Workflow, WorkflowStatus, WorkflowType } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    title: string,
    workflows: Workflow[] | undefined,
    loading: boolean,
    error?: string,
    onRefresh: () => void,
    filter?: boolean,
    headerDescription?: string,
    columns: string[],
    warning?: string
}

const WorkflowsTable : React.FC<Props> = ({title, warning, columns, workflows, headerDescription = "", loading, error, onRefresh, filter = true}) => {

    const config = useConfig();
    const navigate = useNavigate();
    const [controlPlaneWorkflowsEnabled, setControlPlaneWorkflowsEnabled] = useState<boolean>(true);
    const [cellWorkflowsEnabled, setCellWorkflowsEnabled] = useState<boolean>(true);

    const {
        items,
        collectionProps,
        filterProps
      } = useCollection(filterSelectedWorkflows(workflows || []), {
        filtering: {},
        sorting: {
          defaultState: {
            sortingColumn: {
                sortingField: 'startTime',
            },
            isDescending: true,
          },
        },
      });

    function createStepFunctionsIsengardLink(workflow: Workflow) {
        if (config === undefined) {
            return "";
        }
        const destination = `states/home?region=${config.region}#/executions/details/${workflow.executionArn}`;
        return createIsengardLink(config.isengardEndpoint, workflow.accountId, 'ReadOnly', destination);
    }

    function renderWorkflowStatus(status: WorkflowStatus) {
        if (status === 'FAILED') {
            return <StatusIndicator type='error'>FAILED</StatusIndicator>;
        } else if (status === 'SUCCEEDED') {
            return <StatusIndicator type='success'>SUCCEEDED</StatusIndicator>;
        } else if (status === 'RUNNING') {
            return <StatusIndicator type='in-progress'>RUNNING</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{status}</StatusIndicator>;
        }
    }

    let allDefinitions: TableProps.ColumnDefinition<Workflow>[] = [
        {
            id: "workflowName",
            header: "Workflow",
            cell: (item: Workflow) => <Link href={createStepFunctionsIsengardLink(item)} target="_blank">{item.workflowName}</Link>,
            sortingField: 'workflowName',
            maxWidth: 400
        },
        {
            id: "brokerId",
            header: "Broker",
            cell: (item: Workflow) => <Link href={`/broker/${item.brokerId}`} onFollow={(event) => {
                event.preventDefault();
                navigate(`/broker/${item.brokerId}`)
            }}>{item.brokerId}</Link>
        },
        {
            id: "type",
            header: "Type",
            cell: (item: Workflow) => item.workflowType === WorkflowType.BROKER_CELL_WORKFLOW ? "Cell" : "Control Plane"
        },
        {
            id: "cell",
            header: "Cell",
            cell: (item: Workflow) => item.workflowType === WorkflowType.BROKER_CELL_WORKFLOW ? item.cellId : "Control Plane"
        },
        {
            id: "maintenance",
            header: "Maintenance",
            cell: (item: Workflow) => item.executionArn.includes("Workflow:mw-") ? "true" : "false",
            maxWidth: 110
        },
        {
            id: "status",
            header: "Status",
            cell: (item: Workflow) => renderWorkflowStatus(item.status),
            sortingField: 'status',
        },
        {
            id: "startTime",
            header: "Start Time",
            cell: (item: Workflow) => item.startTime ? isoFormat(item.startTime) : "",
            sortingField: 'startTime',
        },
        {
            id: "endTime",
            header: "End Time",
            cell: (item: Workflow) => item.endTime ? isoFormat(item.endTime) : "",
            sortingField: 'endTime',
        }
    ]

    let columnDefinitions = allDefinitions.filter(def => columns.includes(def.id!));

    if (config === undefined) {
        return <></>
    }
    
    function filterSelectedWorkflows(workflows: Workflow[]): Workflow[] {
        let ret = [...workflows];
        if (!controlPlaneWorkflowsEnabled) {
            ret = ret.filter(w => w.workflowType !== WorkflowType.CONTROL_PLANE_WORKFLOW);
        }
        if (!cellWorkflowsEnabled) {
            ret = ret.filter(w => w.workflowType !== WorkflowType.BROKER_CELL_WORKFLOW);
        }
        return ret;
    }

    return (
        <SpaceBetween size='xs' direction='vertical'>
            <Table
                {...collectionProps}
                items={items}
                filter={filter ?
                    <SpaceBetween size='xs' direction='vertical'>
                        <TextFilter
                            {...filterProps}
                            filteringPlaceholder="Search Workflow"
                        />
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Checkbox checked={controlPlaneWorkflowsEnabled} onChange={(event) => setControlPlaneWorkflowsEnabled(event.detail.checked)}>Control Plane</Checkbox>
                            <Checkbox checked={cellWorkflowsEnabled} onChange={(event) => setCellWorkflowsEnabled(event.detail.checked)}>Cell</Checkbox>
                        </SpaceBetween>
                    </SpaceBetween> : null
                }
                columnDefinitions={columnDefinitions}
                loading={loading}
                header={
                    <Header
                        description={<SpaceBetween direction='vertical' size='xs'>
                            {headerDescription}
                            {warning && <StatusIndicator type='warning'>{warning}</StatusIndicator> }
                            {error && <StatusIndicator type='error'>{error}</StatusIndicator> }
                            </SpaceBetween>}
                        actions={
                            <Button disabled={loading} iconName="refresh" onClick={() => {
                                onRefresh();
                            }} />
                        } 
                    >{title}</Header>
                }
                wrapLines={true}
                empty="No Workflows found"
            />
        </SpaceBetween>
    )
}

export default WorkflowsTable;